import triptico from "assets/images/triptico.jpg";
import LazyIframe from "components/common/iframe/lazyIframe";
import LayoutStep from "components/common/layout/layoutStep";
import Button from "components/ui/atoms/button/button";
import { titleTrainingDays } from "data/config";
import Img from "gatsby-image";
import Link from "gatsby-link";
import React from "react";

import SEO from "../../components/SEO/SEO";

const Step3 = () => {
  return (
    <LayoutStep>
      <SEO
        title={`Training offer brochure | ${titleTrainingDays}`}
        description={
          "Introduction to the three Initial Qualification Programmes that students can attend in the Ramon y Cajal Highschool."
        }
        canonical={"training-days/step-3/"}
      />
      <div className="container step">
        <p className={"h1"} id={"title"}>
          {titleTrainingDays} - Step 3
        </p>
        <h1 className={"slideLeft"}> TRAINING OFFER BROCHURE </h1>
        <section className="videoCart">
          <main className={"slideLeft s05"}>
            <div className={"video--bloque--a"}></div>
            <div className={"embed-iframe"}>
              <a href={"/downloads/training-offer-brochure.pdf"} rel="noreferrer" target={"_blank"}>
                <img
                  src={triptico}
                  width={"1280"}
                  height={"720"}
                  alt={"Introducing young people in the labor market. Triptych"}
                />
              </a>
            </div>
          </main>
          <aside className={"slideLeft s1"}>
            <div className="stepNavigation">
              <Link to="/training-days/step-2/#top" className={"stepNavigation__button--back "}>
                {" "}
                <span className={"stepNavigation__button--back--right"}> </span> Back{" "}
              </Link>
              <Link to="/training-days/step-4/#top" className={"stepNavigation__button--next"}>
                {" "}
                <span className={"stepNavigation__button--next--left"}> </span> Next{" "}
              </Link>
            </div>
            <p>
              Introduction to the three Initial Qualification Programmes that students can attend in the Ramon y Cajal
              Highschool.
            </p>
            <p>
              It gives information about the admission requirements, career oportunities, colaborations, methodology,
              modules, professional qualifications, adress and webpages.
            </p>
            <a
              className={"pdf cover--right"}
              href={"/downloads/training-offer-brochure.pdf"}
              rel="noreferrer"
              target={"_blank"}
            >
              {" "}
              View Training offer Brochure{" "}
            </a>
          </aside>
        </section>
      </div>
    </LayoutStep>
  );
};

export default Step3;
